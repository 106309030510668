import { store } from '@/store';
import { UserGetters } from '@/store/modules/user/user.getters';
import { IPermission } from '@/shared/interfaces/permissions-interface';

export default function hasPermission(permission: string | undefined) {
  if (!permission) {
    return true
  }
  const currentUser = store.getters[UserGetters.getUser]

  if (!currentUser || !currentUser.permissions) {
    return false
  }

  return currentUser.permissions.some((perm:IPermission) => perm.name === permission)
}
