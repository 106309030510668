















































































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field';
import { mapGetters } from 'vuex';
import { UserGetters } from '@/store/modules/user/user.getters';
import User from '@/modules/user/models/user.model';
import ClientNotFound from '@/shared/components/ClientNotFound.vue';
import PageHeader from '@/components/layout/PageHeader.vue';
import { GeneralRoutes } from '@/router/routes/general';
import { IProjectPayload } from '@/modules/project/interfaces/project-response.interface';
import SelectOption from '@/shared/classes/components/form/select-option';
import {
  ProjectStatusKeys,
  ProjectStatusKeysData, ProjectTypeKeys, ProjectTypeKeysData
} from '@/shared/configs/project.config';
import ProjectService from '@/services/ProjectService';
import ProjectsDelete from '@/modules/project/ProjectsDelete.vue';
import http from '@/shared/helpers/http';

@Component({
  components: { PageHeader, ClientNotFound, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class Project extends Vue {
  FieldSizes = FieldSizes
  error: any = null
  user!: User
  loading: boolean = true
  GeneralRoutes = GeneralRoutes
  cmsTypes:cmsOptions[] = []

  private project: IProjectPayload | null = null

  private statusOptions: SelectOption[] = [
    new SelectOption().setKey(ProjectStatusKeys.active).setTitle(ProjectStatusKeysData[ProjectStatusKeys.active]),
    new SelectOption().setKey(ProjectStatusKeys.disabled).setTitle(ProjectStatusKeysData[ProjectStatusKeys.disabled])
  ]

  private typeOptions: SelectOption[] = [
    new SelectOption().setKey(ProjectTypeKeys.contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.contract]),
    new SelectOption().setKey(ProjectTypeKeys.programing_contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.programing_contract]),
    new SelectOption().setKey(ProjectTypeKeys.warranty).setTitle(ProjectTypeKeysData[ProjectTypeKeys.warranty]),
    new SelectOption().setKey(ProjectTypeKeys.without_contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.without_contract])
  ]

  created(): void {
    this.getData()
  }

  async getData(): Promise<void> {
    this.loading = true
    await this.getCmsTypes()
    ProjectService.getProject(this.$route.params.id).then(response => {
      this.project = {
        client_id: 0,
        url: '',
        valid_until: '',
        status: '',
        cms_type: '',
        month_price: 0,
        type: '',
        comment: '',
        price_per_hour: 0,
        client: null
      }

      this.project.client_id = response.client_id
      this.project.url = response.url
      this.project.valid_until = response.valid_until
      this.project.status = response.status
      this.project.cms_type = response.cms_type
      this.project.month_price = response.month_price
      this.project.type = response.type
      this.project.comment = response.comment
      this.project.price_per_hour = response.price_per_hour
      this.project.client = response.client || null
      this.project.updated_at = String(response.updated_at).split(' ')[0]

      if (this.project.url) {
        this.$root.$emit('headingChange', {
          heading: this.project.url
        })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  openDeleteModal(): void {
    this.$store.commit('setModalDialog', {
      component: ProjectsDelete,
      id: this.$route.params.id,
      props: {
        title: __('views.projects.delete.title'),
        size: 'md',
        submitLabel: __('form.delete')
      }
    })
  }

  fixLinkUrl(link: string | null) {
    if (!link) { return '' }
    if (link.startsWith('http://') || link.startsWith('https://')) {
      return link
    }
    return `https://${link}`
  }

  statusToString(status: string) {
    if (status === ProjectStatusKeys.active) {
      return ProjectStatusKeysData[ProjectStatusKeys.active]
    }
    if (status === ProjectStatusKeys.disabled) {
      return ProjectStatusKeysData[ProjectStatusKeys.disabled]
    }
    return status
  }

  typeToString(type: string) {
    if (type === ProjectTypeKeys.contract) {
      return ProjectTypeKeysData[ProjectTypeKeys.contract]
    }
    if (type === ProjectTypeKeys.programing_contract) {
      return ProjectTypeKeysData[ProjectTypeKeys.programing_contract]
    }
    if (type === ProjectTypeKeys.warranty) {
      return ProjectTypeKeysData[ProjectTypeKeys.warranty]
    }
    if (type === ProjectTypeKeys.without_contract) {
      return ProjectTypeKeysData[ProjectTypeKeys.without_contract]
    }
    return type
  }

  cmsToString(cms: string) {
    const foundOption = this.cmsTypes.find(cmsOption => cmsOption.key === cms)
    return foundOption ? foundOption.value : cms
  }

  async getCmsTypes() {
    const cmsResponse: cmsOptions[] = await http.get('projects/options').then(response => response.data.data)
    this.cmsTypes = [...cmsResponse]
  }
}

interface cmsOptions {
  key: string
  value: string
}
