import http from '@/shared/helpers/http'
import { IPermission, IPermissionGroup } from '@/shared/interfaces/permissions-interface';
import { AxiosResponse } from 'axios';

export default class PermissionsService {
  static async getPermissions(): Promise<IPermission[]> {
    return http.get('permissions').then(response => response.data.data)
  }

  static async getGroupedPermissions(): Promise<IPermissionGroup[]> {
    return http.get('permissions/grouped').then(response => response.data.data)
  }

  static async updateUserPermissions(payload: number[], userId: number): Promise<AxiosResponse> {
    return http.put(`users/${userId}/permissions`, { permissions: payload })
  }
}
