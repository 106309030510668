import axios, { AxiosRequestConfig } from 'axios'
import router from '@/router'

import tokenHelper from '@/helpers/token'
import { AuthRoutes } from '@/router/routes/auth'
import { store } from '@/store'
import { UserActions } from '@/store/modules/user/user.actions'
import { UserMutations } from '@/store/modules/user/user.mutations'
import { UserGetters } from '@/store/modules/user/user.getters'

// function openErrorModal() {
//   store.commit('setModalDialog', {
//     id: new Date(),
//     hideFooter: true,
//     props: {
//       titleClass: 'c-red text-center fs-18-important w-100',
//       title: __('views.permissions.error'),
//       size: 'md',
//       hideFooter: true,
//       onSuccess: () => {
//         console.log()
//       }
//     }
//   })
// }

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'https://test-api.westerncrm.lt/api'
})

http.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (tokenHelper.getToken()) config.headers.Authorization = `Bearer ${tokenHelper.getToken()}`

    return config
  }
)

http.interceptors.response.use(
  function(response) {
    return response
  },
  async function(error) {
    const originalRequest = error.config
    if (
      error.response.status === 401 &&
      originalRequest.url.includes('auth/refresh') &&
      router.currentRoute.name !== AuthRoutes.register
    ) {
      store.commit(UserMutations.removeRefresh)
      await store.dispatch(UserActions.logout)
      tokenHelper.clearToken()
      router.push({ name: AuthRoutes.login })
    }
    // if (error.response.status === 403) {
    //   openErrorModal()
    //   router.push({ name: GeneralRoutes.dashboard })
    //   return
    // }
    if (
      [401, 403].includes(error.response.status) &&
      !originalRequest._retry &&
      !store.getters[UserGetters.getRefreshing]
    ) {
      originalRequest._retry = true
      store.commit(UserMutations.setRefreshing, true)
      try {
        store.dispatch(UserActions.refreshToken)
      } catch {
        await store.dispatch(UserActions.logout)
        tokenHelper.clearToken()
        router.push({ name: AuthRoutes.login })
        store.commit(UserMutations.setRefreshing, false)
      }
      return error
    } else {
      return Promise.reject(error)
    }
  }
)

export default http
