















































import { Component, Prop, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'
import DatePicker from 'vue2-datepicker'
import moment from 'moment';
import MultiSelect from 'vue-multiselect';
import SelectOption from '@/shared/classes/components/form/select-option';
import { datePickerSettings } from '@/helpers/datePickerSettings';
import 'vue2-datepicker/locale/lt';

@Component({
  components: { MultiSelect, DatePicker }
})
export default class PeriodField extends AbstractField {
  @Prop({ default: '' }) hint?: string
  @Prop({ default: null }) dateOptions?:SelectOption[] | null
  selectOptions: SelectOption[] = []
  select: any = {}
  dateIncrement: number = 0
  dateValue: (Date|null)[] = [
    null, null
  ]

  $refs!: any
  datePickerSettings = datePickerSettings

  @Watch('dateValue', { deep: true }) onDateValueChange(newVal:any) {
    this.model.from = moment(newVal[0]).format('YYYY-MM-DD')
    this.model.to = moment(newVal[1]).format('YYYY-MM-DD')
    this.emitChange(this.model)
  }

  created(): void {
    this.setSelectOptions()
    this.preselectCurrentMoth()
  }

  setSelectOptions() {
    if (this.dateOptions) {
      this.selectOptions = this.dateOptions
      return
    }
    this.selectOptions = [
      new SelectOption().setKey('current-month').setTitle('Einamasis mėnuo'),
      new SelectOption().setKey('last-month').setTitle('Praėjęs mėnuo'),
      new SelectOption().setKey('select').setTitle('Pasirinktina data')
    ]
  }

  preselectCurrentMoth() {
    const currentMonthOption = this.selectOptions.find((option:SelectOption) => option.key === 'current-month')
    if (currentMonthOption) {
      this.setValue(currentMonthOption)
      setTimeout(() => {
        this.select = currentMonthOption
      }, 10)
    }
  }

  incrementMonth(subtract: boolean = false) {
    this.dateIncrement += subtract ? -1 : 1
    if (this.dateIncrement === 0) {
      const currentMonthOption = this.selectOptions.find((option: SelectOption) => option.key === 'current-month')
      this.setValue(currentMonthOption)
      this.select = currentMonthOption
      return
    }
    if (this.dateIncrement === -1) {
      const lastMonthOption = this.selectOptions.find((option: SelectOption) => option.key === 'last-month')
      this.setValue(lastMonthOption)
      this.select = lastMonthOption
      return
    }
    this.model = {
      from: moment()
        .add(this.dateIncrement, 'M')
        .startOf('month')
        .format('YYYY-MM-DD'),
      to: moment()
        .add(this.dateIncrement, 'M')
        .endOf('month')
        .format('YYYY-MM-DD')
    }
    this.dateValue = [
      new Date(this.model.from), new Date(this.model.to)
    ]
    this.select = {
      key: 'custom',
      title: moment(this.model.to).locale('lt').format('YYYY MMMM')
    }
  }

  setValue(value: any) {
    if (value.key === 'select') {
      this.model = {
        from: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment()
          .endOf('month')
          .format('YYYY-MM-DD')
      }
      this.dateValue = [
        new Date(this.model.from), new Date(this.model.to)
      ]
      this.$refs[`${this.id}-range`].openPopup()
      return
    }

    if (value.key === 'last-month') {
      this.dateIncrement = -1
      this.model = {
        from: moment()
          .subtract(1, 'M')
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment()
          .subtract(1, 'M')
          .endOf('month')
          .format('YYYY-MM-DD')
      }
      this.dateValue = [
        new Date(this.model.from), new Date(this.model.to)
      ]
      return
    }

    if (value.key === 'current-month') {
      this.dateIncrement = 0
      this.model = {
        from: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment()
          .endOf('month')
          .format('YYYY-MM-DD')
      }
      this.dateValue = [
        new Date(this.model.from), new Date(this.model.to)
      ]
      return
    }

    this.model = {
      from: '',
      to: ''
    }
  }
}
