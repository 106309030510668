
































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field'
import Template from '@/modules/offer-template/models/template.model'
import getAll from '@/shared/configs/vuex/get-all.config'
import { Container, Draggable } from 'vue-dndrop'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import RadioField from '@/shared/components/form/fields/RadioField.vue';
import SelectOption from '@/shared/classes/components/form/select-option';
import OfferService from '@/services/OfferService';
import InvoicesService from '@/services/InvoicesService';
import _ from 'lodash';
import { IOfferInformationItem } from '@/modules/offer/interfaces/offer-response.interface';
import DropdownField from '@/shared/components/form/fields/DropdownField.vue';

interface DropResult {
  removedIndex: number
  addedIndex: number
  payload: any
}

@Component({
  components: { DropdownField, RadioField, LoadingSpinner, PageHeader, Form, Container, Draggable },
  methods: { __ }
})
export default class TemplatesEdit extends Vue {
  @Prop() meta!: any
  itemsBlockKey: number = 0
  FieldSizes = FieldSizes
  previousItems: IOfferInformationItem[] = []
  data: Template = new Template({})
  error: any = null
  loading: boolean = true
  discountTypes: SelectOption[] = [
    new SelectOption().setKey('none').setTitle(__('views.invoices.form.no_discount')),
    new SelectOption().setKey('discount').setTitle(__('views.invoices.form.sum_discount')),
    new SelectOption().setKey('discount_percentage').setTitle(__('views.invoices.form.percent_discount'))
  ]

  vatOptions: SelectOption[] = []
  unitOptions: SelectOption[] = []

  async submit(): Promise<void> {
    this.loading = true
    this.error = null
    await OfferService.updateOfferTemplate(this.data, Number(this.$route.params.id))
      .catch((error: any) => {
        this.loading = false
        this.$nextTick(() => {
          this.error = error
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  async getOfferItemOptions(): Promise<void> {
    const vatOptions = await InvoicesService.getVatOptions()
    vatOptions.forEach(vatItem => {
      this.vatOptions.push(new SelectOption().setKey(vatItem.percentage).setTitle(vatItem.title))
    })

    const unitOptions = await InvoicesService.getUnitOptions()
    unitOptions.forEach(unitItem => {
      this.unitOptions.push(new SelectOption().setKey(unitItem.title).setTitle(unitItem.title))
    })
  }

  handleDrop(dropResult: DropResult) {
    const elementArray: any = _.cloneDeep(this.data.information)
    elementArray.splice(dropResult.removedIndex, 1)
    elementArray.splice(dropResult.addedIndex, 0, this.data.information[dropResult.removedIndex])
    elementArray.forEach((item:IOfferInformationItem, index:number) => {
      item.position = index
    })
    this.data.information = elementArray
    this.itemsBlockKey++
  }

  removeOfferItem(index: number): void {
    this.data.information.splice(index, 1)
    this.itemsBlockKey++
  }

  onItemChange(data:IOfferInformationItem | string, item:IOfferInformationItem):void {
    if (typeof data === 'string') {
      if (typeof item?.title !== 'undefined') {
        item.title = data
      }
      return
    }
    if (typeof data?.amount !== 'undefined') {
      item.amount = data.amount
    }
    if (typeof data?.vat !== 'undefined') {
      item.vat = Number(data.vat)
    }
    if (typeof data?.price_per_unit !== 'undefined') {
      item.price_per_unit = data.price_per_unit
    }
    const foundUnit = this.unitOptions.find(unit => unit.title === data?.unit)
    item.unit = foundUnit?.key || item.unit

    item.total = (Number(item.amount) * Number(item.price_per_unit)).toFixed(2)
    item.total_with_vat = (Number(item.total) * (1 + Number(item.vat) / 100)).toFixed(2)
    item.total_vat = (Number(item.total_with_vat) - Number(item.total)).toFixed(2)
    this.data.information = _.cloneDeep(this.data.information)
    this.$nextTick(() => {
      this.itemsBlockKey++;
    })

    this.calculateSums()
  }

  addOfferHeading() {
    this.data.information.push({
      type: 'heading',
      position: this.data.information.length,
      title: ''
    })
  }

  addOfferService() {
    this.data.information.push({
      type: 'service',
      position: this.data.information.length,
      title: '',
      amount: '',
      price_per_unit: '',
      unit: '',
      vat: 0
    })
  }

  async getTemplateData() {
    const templateData = await OfferService.getOfferTemplate(Number(this.$route.params.id))
    this.data = new Template(templateData)
  }

  calculateSums() {
    if (!this.data) {
      return
    }
    let newTotal = 0
    let newTotalWithVat = 0
    let newTotalVat = 0
    let vatRate:number | null = null
    this.data.information.forEach(information => {
      if (information.type !== 'service') {
        return
      }
      if (vatRate === null) {
        vatRate = information.vat || null
      }
      newTotal += Number(information.total)
      newTotalWithVat += Number(information.total_with_vat)
      newTotalVat += (Number(information.total_with_vat) - Number(information.total))
    })
    if (vatRate === null) {
      vatRate = 0
    }
    const sumDiscount = this.data.discount_type === 'discount' ? Number(this.data.discount) : 0
    const percentDiscount = this.data.discount_type === 'discount_percentage' ? 1 - (Number(this.data.discount_percentage) / 100) : 1
    this.data.total = ((newTotal - sumDiscount) * percentDiscount).toFixed(2)
    this.data.total_with_vat = ((newTotalWithVat - sumDiscount * (1 + vatRate / 100)) * percentDiscount).toFixed(2)
    this.data.total_vat = ((newTotalVat - sumDiscount * (vatRate / 100)) * percentDiscount).toFixed(2)
  }

  @Watch('data.discount_type')
  @Watch('data.discount')
  @Watch('data.discount_percentage')
  onDiscountChange(): void {
    this.calculateSums()
  }

  async created(): Promise<void> {
    await getAll(this.$store)
    await this.getOfferItemOptions()
    await this.getTemplateData()
    this.loading = false
  }

  recalculateItemRow(item: IOfferInformationItem, field:string) {
    if (field === 'vat' || field === 'price_per_unit' || field === 'amount') {
      item.total = Number(item.amount) * Number(item.price_per_unit)
      item.total_with_vat = item.total * (1 + Number(item.vat) / 100)
      item.total = item.total.toFixed(2)
      item.total_with_vat = item.total_with_vat.toFixed(2)
      item.total_vat = (Number(item.total_with_vat) - Number(item.total)).toFixed(2)
    }

    if (field === 'total') {
      item.price_per_unit = Number(item.total) / Number(item.amount)
      item.total_with_vat = Number(item.total) * (1 + Number(item.vat) / 100)
      item.price_per_unit = item.price_per_unit.toFixed(2)
      item.total_with_vat = item.total_with_vat.toFixed(2)
      item.total_vat = (Number(item.total_with_vat) - Number(item.total)).toFixed(2)
    }

    if (field === 'total_with_vat') {
      item.total = Number(item.total_with_vat) / (1 + Number(item.vat) / 100)
      item.price_per_unit = Number(item.total) / Number(item.amount)
      item.price_per_unit = item.price_per_unit.toFixed(2)
      item.total = item.total.toFixed(2)
      item.total_vat = (Number(item.total_with_vat) - Number(item.total)).toFixed(2)
    }
    this.calculateSums()
  }

  onItemFieldBlur(item: IOfferInformationItem, field: keyof IOfferInformationItem) {
    if (!(field in item)) {
      return
    }
    // @ts-ignore
    item[field] = Number(item[field]).toFixed(2)
    this.itemsBlockKey++
  }

  @Watch('data.information', { deep: true })
  onItemsChange(newItems: IOfferInformationItem[]): void {
    if (!this.previousItems.length) {
      this.previousItems = JSON.parse(JSON.stringify(newItems))
      return
    }

    newItems.forEach((newItem, index) => {
      const oldItem = this.previousItems[index]
      if (!oldItem) return

      const changedField = Object.keys(newItem).find(
        key => newItem[key as keyof IOfferInformationItem] !== oldItem[key as keyof IOfferInformationItem]
      )

      if (changedField) {
        this.recalculateItemRow(newItem, changedField)
      }
    })

    this.previousItems = JSON.parse(JSON.stringify(newItems))
  }
}
