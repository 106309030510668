






















import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import DocumentClass from './models/document.model'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import IDocumentGroup from '@/modules/documents/interfaces/document-group';
import DocumentService from '@/services/DocumentService';
import DocumentDelete from '@/modules/documents/DocumentDelete.vue';
import { tableCount } from '@/helpers/tableCount';
import PdfPreviewModal from '@/shared/components/PdfPreviewModal.vue';
import hasPermission from '@/shared/helpers/has-permission';

@Component({
  components: { PdfPreviewModal, DataTable, PageHeader },
  methods: { __, hasPermission }
})
export default class DocumentIndex extends Vue {
  table: DataTableBase | null = null
  documentGroups: null | IDocumentGroup[] = null
  documentId: string | null = null

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newRoute: any): void {
    if (typeof newRoute.params.id === 'undefined') {
      this.$router.replace(GeneralRoutes.documentsByGroup + '/contract/?sort=-date')
    }
    if (this.table !== null) {
      this.table.setEndpoint(this.dataRoute())
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.getDocumentGroups()
    if (typeof this.$route.params.id === 'undefined') {
      this.$router.replace(GeneralRoutes.documentsByGroup + '/contract?sort=-date')
    }
    this.table = new DataTableBase()
      .setId(tableCount.tableID.documentsIndex)
      .setEndpoint(this.dataRoute())
      .setModel(DocumentClass)
      .setRowClickCallback((item: DocumentClass) => {
        this.$router.push({ name: GeneralRoutes.document, params: { id: (item.id || '').toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.documents.index.table.columns.title'))
          .setLink({ link: GeneralRoutes.document, linkParam: 'id' }),
        new DataTableHeader()
          .setKey('client.company_name')
          .setLabel(__('views.documents.index.table.columns.client'))
          .setLink(hasPermission('clients.view') ? { link: GeneralRoutes.client, linkParam: 'client.id' } : null),
        new DataTableHeader()
          .setKey('date')
          .setLabel(__('views.documents.index.table.columns.date')),
        new DataTableHeader().setKey('number').setLabel(__('views.documents.index.table.columns.number'))
      ])
      .setActions([
        new DataTableAction().setIcon('view mr-2').setAction(this.preview),
        new DataTableAction()
          .setIcon('edit mr-2')
          .setAction(this.editDocument)
          .setVisible((item: any) => !!item.title),
        new DataTableAction().setIcon('trash').setAction(this.openDeleteModal)
      ])
  }

  preview(item: any): void {
    this.documentId = item.item.id.toString()
    const previewModal = this.$refs.previewModal as any
    previewModal.showModal()
  }

  addNew(): void {
    this.$router.push({ name: GeneralRoutes.documentsCreate })
  }

  openDeleteModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: DocumentDelete,
      id: row.item.id,
      props: {
        title: __('views.documents.delete.title', { document: row.item.title }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }

  editDocument(item: any): void {
    this.$router.push({
      name: GeneralRoutes.document,
      query: this.$route.query,
      params: { id: item.item.id.toString() }
    })
  }

  dataRoute(): string {
    if (typeof this.$route.params.id !== 'undefined') {
      return `/documents/by-group/${this.$route.params.id}`
    }
    return '/documents'
  }

  async getDocumentGroups(): Promise<void> {
    this.documentGroups = await DocumentService.getDocumentGroups()
  }
}
