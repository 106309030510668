



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Submittable } from '@/shared/interfaces/submittable';
import { IPermission, IPermissionGroup } from '@/shared/interfaces/permissions-interface';
import PermissionsService from '@/services/PermissionsService';

interface IItemDetails {
  permissions: IPermissionGroup[]
}

@Component
export default class UsersPermissions extends Vue implements Submittable {
  @Prop() itemDetails!: IItemDetails
  error: string | null = null

  togglePermission(permission: IPermission) {
    permission.active = !permission.active
  }

  submitDialog(): void {
    this.error = null
    const payload: number[] = []
    this.itemDetails.permissions.forEach(permissionGroup => {
      permissionGroup.permissions.forEach(permission => {
        if (permission.active) {
          payload.push(permission.id)
        }
      })
    })
    PermissionsService.updateUserPermissions(payload, Number(this.$route.params.id)).then(() => {
      this.$root.$emit('formSuccess')
    }).catch((error: any) => {
      this.error = error.response.data.message
    })
  }
}
