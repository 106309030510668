


















import { Component, Prop, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'
import DatePicker from 'vue2-datepicker'
import moment from 'moment';
import { datePickerSettings } from '@/helpers/datePickerSettings';
import 'vue2-datepicker/locale/lt';

@Component({
  components: {
    DatePicker
  }
})
export default class DateField extends AbstractField {
  @Prop() minDate?: string

  @Watch('dateValue') onDateValueChange() {
    this.model = this.dateValue ? moment(this.dateValue).format('YYYY-MM-DD') : undefined
    this.emitChange(this.model)
  }

  @Watch('minDate') onMinDateChange(newVal: string) {
    const minDate = new Date(newVal)
    if (this.dateValue && this.dateValue <= minDate) {
      this.dateValue = new Date(minDate.setDate(minDate.getDate() + 1))
    }
  }

  dateValue: Date | null = null

  datePickerSettings = datePickerSettings

  created(): void {
    this.dateValue = this.model ? new Date(this.model) : null
  }

  disableBeforeDate(date: Date) {
    if (!this.minDate) {
      return false
    }
    return date < new Date(this.minDate)
  }
}
