import { GeneralRoutes } from '@/router/routes/general'
import { RouteInfo } from '@/shared/interfaces/route-info.interface';

export const routeList: RouteInfo[] = [
  {
    name: 'dashboard',
    icon: require('@/assets/images/sidebar/home.svg'),
    to: GeneralRoutes.dashboard
  },
  {
    name: 'clients',
    icon: require('@/assets/images/sidebar/person.svg'),
    to: GeneralRoutes.clientsIndex,
    permission: 'clients.view'
  },
  {
    name: 'requests',
    icon: require('@/assets/images/sidebar/request.svg'),
    to: GeneralRoutes.requestByStatus,
    permission: 'requests.view'
  },
  {
    name: 'invoices',
    icon: require('@/assets/images/sidebar/invoice.svg'),
    children: [
      {
        name: 'invoices',
        to: GeneralRoutes.invoicesIndex,
        permission: 'invoices.view'
      },
      {
        name: 'prepaid-invoices',
        to: GeneralRoutes.prepaidInvoicesIndex,
        permission: 'invoices.view'
      },
      {
        name: 'invoice-templates',
        to: GeneralRoutes.invoiceTemplatesIndex,
        permission: 'invoice-templates.view'
      }
    ]
  },
  {
    name: 'offers',
    icon: require('@/assets/images/sidebar/offer.svg'),
    children: [
      {
        name: 'offers',
        to: GeneralRoutes.offersIndex,
        permission: 'offers.view'
      },
      {
        name: 'offer-templates',
        to: GeneralRoutes.offerTemplatesIndex,
        permission: 'offer-templates.view'
      }
    ]
  },
  {
    name: 'projects',
    icon: require('@/assets/images/sidebar/offer.svg'),
    to: GeneralRoutes.projectsIndex,
    permission: 'projects.view'
  },
  {
    name: 'procurements',
    icon: require('@/assets/images/sidebar/procurement.svg'),
    children: [
      {
        name: 'procurements',
        to: GeneralRoutes.procurementIndex,
        permission: 'procurements.view'
      },
      {
        name: 'procurement-companies',
        to: GeneralRoutes.procurementCompanies,
        permission: 'procurements.view'
      },
      {
        name: 'procurement-search',
        to: GeneralRoutes.procurementSearch,
        permission: 'procurements.view'
      },
      {
        name: 'procurement-settings',
        to: GeneralRoutes.procurementSettings,
        permission: 'procurements.view'
      }
    ]
  },
  {
    name: 'documents',
    icon: require('@/assets/images/sidebar/document.svg'),
    children: [
      {
        name: 'documents-index',
        to: GeneralRoutes.documentsIndex,
        query: { sort: '-date' },
        permission: 'documents.view'
      },
      {
        name: 'documents-templates-index',
        to: GeneralRoutes.documentsTemplatesIndex,
        permission: 'document-templates.view'
      }
    ]
  },
  {
    name: 'settings',
    icon: require('@/assets/images/sidebar/settings.svg'),
    children: [
      {
        name: 'settings-documents',
        to: GeneralRoutes.settingsDocuments,
        permission: 'settings.view'
      },
      {
        name: 'settings-general',
        to: GeneralRoutes.settingsGeneral,
        permission: 'settings.view'
      },
      {
        name: 'reminder-templates',
        to: GeneralRoutes.reminderTemplatesIndex,
        permission: 'reminder-templates.view'
      },
      {
        name: 'default-templates',
        to: GeneralRoutes.defaultTemplatesIndex,
        permission: 'default-templates.view'
      }
    ]
  },
  {
    name: 'payments',
    icon: require('@/assets/images/sidebar/payment.svg'),
    to: GeneralRoutes.paymentsIndex,
    permission: 'payments.view'
  },
  {
    name: 'services',
    icon: require('@/assets/images/sidebar/product.svg'),
    to: GeneralRoutes.servicesIndex,
    permission: 'services.view'
  },
  {
    name: 'users',
    icon: require('@/assets/images/sidebar/person.svg'),
    to: GeneralRoutes.usersIndex,
    permission: 'users.view'
  },
  {
    name: 'workers',
    icon: require('@/assets/images/sidebar/person.svg'),
    to: GeneralRoutes.workersIndex,
    permission: 'employees.view'
  }
]
