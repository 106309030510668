





























































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import User from '@/modules/user/models/user.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { UserTypes } from '@/shared/configs/user/user.config'
import { mapGetters } from 'vuex'
import { UserGetters } from '@/store/modules/user/user.getters'
import UsersEdit from '@/modules/user/UsersEdit.vue';
import UserDisable from '@/modules/user/UsersDisable.vue';
import PermissionsService from '@/services/PermissionsService';
import { IPermissionGroup, IUserPermission } from '@/shared/interfaces/permissions-interface';
import UsersPermissions from '@/modules/user/UsersPermissions.vue';
import _ from 'lodash';

@Component({
  methods: { __ },
  computed: {
    ...mapGetters({
      currentUser: UserGetters.getUser
    })
  }
})
export default class UserPage extends Vue {
  user: User | any = null
  currentUser!: boolean
  permissions: IPermissionGroup[] = []
  permissionsLoading: boolean = true

  mounted(): void {
    this.getData()
  }

  async getData(): Promise<void> {
    await http
      .get(`/users/${this.$route.params.id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.user = new User(item.data)
        this.$root.$emit('headingChange', {
          heading: `${this.user.firstName} ${this.user.lastName}`
        })
      })
      .then(() => {
        this.getPermissions()
      })
  }

  getInitials(user: User): string {
    return user.firstName.substring(0, 1) + user.lastName.substring(0, 1)
  }

  openEditModal(): void {
    this.$store.commit('setModalDialog', {
      component: UsersEdit,
      id: this.user.id,
      props: {
        title: __('views.users.edit.title', { user: this.user.fullName })
      }
    })
  }

  openPermissionsModal(): void {
    if (!this.user || !this.permissions) {
      return
    }
    this.$store.commit('setModalDialog', {
      component: UsersPermissions,
      id: this.user.id,
      props: {
        title: __('views.users.permissions.modal', { user: this.user.fullName }),
        onSuccess: () => {
          console.log('success')
          this.getData()
        }
      },
      details: {
        permissions: _.cloneDeep(this.permissions)
      }
    })
  }

  openDisableModal(): void {
    this.$store.commit('setModalDialog', {
      component: UserDisable,
      id: this.user.id,
      props: {
        title: __('views.users.disable.text', { user: this.user.title }),
        size: 'md',
        submitLabel: __('form.disable'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }

  translateType(type:string): string {
    if (type === UserTypes.user) { return __('common-names.user') }
    if (type === UserTypes.admin) { return __('common-names.admin') }
    return type
  }

  getPermissions() {
    this.permissionsLoading = true
    PermissionsService.getGroupedPermissions().then(response => {
      this.setPermissionStatuses(response)
    }).finally(() => {
      this.permissionsLoading = false
    })
  }

  setPermissionStatuses(permissionResponse: IPermissionGroup[]) {
    if (!this.user) {
      return
    }
    permissionResponse.forEach(group => {
      group.isHidden = true
      group.permissions.forEach(permission => {
        const userHasPermission = this.user.permissions.find((userPermission:IUserPermission) => {
          return userPermission.id === permission.id
        })
        permission.active = !!userHasPermission
        if (!permission.isHidden) {
          group.isHidden = false
        }
      })
    })
    this.permissions = permissionResponse
  }
}
