



import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import User from '@/modules/user/models/user.model'
import { GeneralRoutes } from '@/router/routes/general'
import { UserTypes } from '@/shared/configs/user/user.config'
import SelectOption from '@/shared/classes/components/form/select-option';

@Component({
  components: { Form },
  methods: { __ }
})
export default class UsersCreate extends Vue {
  form: FormBase | null = null

  created(): void {
    this.form = new FormBase()
      .setId(this.$router.currentRoute.params.id)
      .setEndpoint('/users')
      .setModel(User)
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.usersIndex })
      })
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setKey('first_name')
          .setRequiredDecoration(true)
          .setTitle(__('views.users.form.first_name'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('last_name')
          .setTitle(__('views.users.form.last_name'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.select)
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half)
          .setSelectOptions([
            new SelectOption().setKey(UserTypes.user).setTitle(__('common-names.user')),
            new SelectOption().setKey(UserTypes.admin).setTitle(__('common-names.admin'))
          ])
          .setKey('type')
          .setTitle(__('views.users.form.type')),
        new Field()
          .setKey('email')
          .setTitle(__('views.users.form.email'))
          .setRequiredDecoration(true)
          .setType(FieldTypes.email)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('phone')
          .setTitle(__('views.users.form.phone'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('personal_code')
          .setTitle(__('views.users.form.personal_code'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('position')
          .setTitle(__('views.users.form.position'))
          .setSize(FieldSizes.half)
          .setRequiredDecoration(true),
        new Field()
          .setKey('address')
          .setTitle(__('views.users.form.address'))
          .setSize(FieldSizes.half)
      ])
      .setInjectValues({
        type: UserTypes.user
      })
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
