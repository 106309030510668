






import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import Payment from './models/payment.model'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import PaymentsEdit from '@/modules/payment/PaymentsEdit.vue';
import PaymentDelete from '@/modules/payment/PaymentsDelete.vue';
import { tableCount } from '@/helpers/tableCount';
import hasPermission from '@/shared/helpers/has-permission';

@Component({
  components: { DataTable, PageHeader },
  methods: { __, hasPermission }
})
export default class PaymentsIndex extends Vue {
  table: DataTableBase | any = null

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.paymentsIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.paymentsIndex)
      .setModel(Payment)
      .setEndpoint('/payments-list')
      .setRowClickCallback((item: Payment) => {
        if (!hasPermission('invoices.view')) {
          return
        }
        this.$router.push({ name: GeneralRoutes.invoice, params: { id: (item.invoice_id || '').toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('invoice.number')
          .setLabel(__('views.payments.index.table.columns.invoice'))
          .setLink(hasPermission('invoices.view') ? { link: GeneralRoutes.invoice, linkParam: 'invoice.id' } : null),
        new DataTableHeader()
          .setKey('invoice.client.company_name')
          .setLabel(__('views.payments.index.table.columns.client'))
          .setLink(hasPermission('clients.view') ? { link: GeneralRoutes.client, linkParam: 'invoice.client.id' } : null),
        new DataTableHeader().setKey('amount').setLabel(__('views.payments.index.table.columns.amount')),
        new DataTableHeader().setKey('date').setLabel(__('views.payments.index.table.columns.date')),
        new DataTableHeader().setKey('notes').setLabel(__('views.payments.index.table.columns.notes'))
      ])
      .setActions([
        new DataTableAction().setIcon('edit').setAction(this.openEditModal),
        new DataTableAction().setIcon('trash ml-3').setAction(this.openDeleteModal)
      ])
  }

  openEditModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: PaymentsEdit,
      id: row.item.id,
      details: {
        clientId: row.item.client_id,
        invoiceId: row.item.invoice.id,
        paymentId: row.item.payment_id
      },
      props: {
        title: __('views.payments.edit.title')
      }
    })
  }

  openDeleteModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: PaymentDelete,
      id: row.item.id,
      details: {
        invoiceId: row.item.invoice.id,
        paymentId: row.item.id
      },
      props: {
        title: __('views.payments.delete.title', { payment: row.item.date }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }

  deletePayment(item: any): void {
    this.$router.push({
      name: GeneralRoutes.paymentsDelete,
      query: this.$route.query,
      params: { payment_id: item.item.id.toString(), invoice_id: item.item.invoice.id }
    })
  }
}
