



















































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import SelectOption from '@/shared/classes/components/form/select-option'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field'
import Offer from '@/modules/offer/models/offer.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import _ from 'lodash'
import { GeneralRoutes } from '@/router/routes/general'
import moment from 'moment'
import User from '../user/models/user.model'
import { mapGetters } from 'vuex'
import getAll from '@/shared/configs/vuex/get-all.config'
import { UserGetters } from '@/store/modules/user/user.getters'
import OffersSaveAsTemplate from '@/modules/offer/OffersSaveAsTemplate.vue';
import { InvoiceStatusKeysData, OfferStatusKeys } from '@/shared/configs/offer.config';
import OfferService from '@/services/OfferService';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import InvoicesService from '@/services/InvoicesService';
import { IOfferInformationItem } from '@/modules/offer/interfaces/offer-response.interface';
import ITemplateResponse from '@/modules/offer-template/interfaces/template-response.interface';
import RadioField from '@/shared/components/form/fields/RadioField.vue';
import { Container, Draggable } from 'vue-dndrop'
import DropdownField from '@/shared/components/form/fields/DropdownField.vue';
import hasPermission from '@/shared/helpers/has-permission';

interface DropResult {
  removedIndex: number
  addedIndex: number
  payload: any
}

@Component({
  components: { DropdownField, RadioField, LoadingSpinner, PageHeader, Form, Container, Draggable },
  methods: { __, hasPermission },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class OffersCreate extends Vue {
  @Prop() meta!: any
  itemsBlockKey: number = 0
  FieldSizes = FieldSizes
  user!: User
  loadingNumber: boolean = false
  validNumber: boolean = false
  previousItems: IOfferInformationItem[] = []
  noTemplate: boolean = false
  data: Offer = new Offer({})
  error: any = null
  loading: boolean = true
  discountTypes: SelectOption[] = [
    new SelectOption().setKey('none').setTitle(__('views.invoices.form.no_discount')),
    new SelectOption().setKey('discount').setTitle(__('views.invoices.form.sum_discount')),
    new SelectOption().setKey('discount_percentage').setTitle(__('views.invoices.form.percent_discount'))
  ]

  statusOptions: SelectOption[] = [
    new SelectOption().setKey(OfferStatusKeys.active).setTitle(InvoiceStatusKeysData[OfferStatusKeys.active]),
    new SelectOption().setKey(OfferStatusKeys.approved).setTitle(InvoiceStatusKeysData[OfferStatusKeys.approved]),
    new SelectOption().setKey(OfferStatusKeys.refused).setTitle(InvoiceStatusKeysData[OfferStatusKeys.refused]),
    new SelectOption().setKey(OfferStatusKeys.suspended).setTitle(InvoiceStatusKeysData[OfferStatusKeys.suspended])
  ]

  vatOptions: SelectOption[] = []
  unitOptions: SelectOption[] = []

  async submit(): Promise<void> {
    this.loading = true
    this.error = null
    await OfferService.createOffer(this.data)
      .then((offer: Offer) => {
        if (offer.id) this.$router.push({ name: GeneralRoutes.offer, params: { id: offer.id.toString() } })
      })
      .catch((error: any) => {
        this.loading = false
        this.$nextTick(() => {
          this.error = error
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  async getOfferData(id: number): Promise<void> {
    const offerData = await OfferService.getOffer(id)
    this.data = new Offer(offerData)
  }

  async getOfferItemOptions(): Promise<void> {
    const vatOptions = await InvoicesService.getVatOptions()
    vatOptions.forEach(vatItem => {
      this.vatOptions.push(new SelectOption().setKey(vatItem.percentage).setTitle(vatItem.title))
    })

    const unitOptions = await InvoicesService.getUnitOptions()
    unitOptions.forEach(unitItem => {
      this.unitOptions.push(new SelectOption().setKey(unitItem.title).setTitle(unitItem.title))
    })
  }

  handleDrop(dropResult: DropResult) {
    const elementArray: any = _.cloneDeep(this.data.information)
    elementArray.splice(dropResult.removedIndex, 1)
    elementArray.splice(dropResult.addedIndex, 0, this.data.information[dropResult.removedIndex])
    elementArray.forEach((item:IOfferInformationItem, index:number) => {
      item.position = index
    })
    this.data.information = elementArray
    this.itemsBlockKey++
  }

  removeOfferItem(index: number): void {
    this.data.information.splice(index, 1)
    this.itemsBlockKey++
  }

  onItemChange(data:IOfferInformationItem | string, item:IOfferInformationItem):void {
    if (typeof data === 'string') {
      if (typeof item?.title !== 'undefined') {
        item.title = data
      }
      return
    }
    if (typeof data?.amount !== 'undefined') {
      item.amount = data.amount
    }
    if (typeof data?.vat !== 'undefined') {
      item.vat = Number(data.vat)
    }
    if (typeof data?.price_per_unit !== 'undefined') {
      item.price_per_unit = data.price_per_unit
    }
    const foundUnit = this.unitOptions.find(unit => unit.title === data?.unit)
    item.unit = foundUnit?.key || item.unit

    item.total = (Number(item.amount) * Number(item.price_per_unit)).toFixed(2)
    item.total_with_vat = (Number(item.total) * (1 + Number(item.vat) / 100)).toFixed(2)
    item.total_vat = (Number(item.total_with_vat) - Number(item.total)).toFixed(2)
    this.data.information = _.cloneDeep(this.data.information)
    this.$nextTick(() => {
      this.itemsBlockKey++;
    })

    this.calculateSums()
  }

  calculateSums() {
    if (!this.data) {
      return
    }
    let newTotal = 0
    let newTotalWithVat = 0
    let newTotalVat = 0
    let vatRate:number | null = null
    this.data.information.forEach(information => {
      if (information.type !== 'service') {
        return
      }
      if (vatRate === null) {
        vatRate = information.vat || null
      }
      newTotal += Number(information.total)
      newTotalWithVat += Number(information.total_with_vat)
      newTotalVat += (Number(information.total_with_vat) - Number(information.total))
    })
    if (vatRate === null) {
      vatRate = 0
    }
    const sumDiscount = this.data.discount_type === 'discount' ? Number(this.data.discount) : 0
    const percentDiscount = this.data.discount_type === 'discount_percentage' ? 1 - (Number(this.data.discount_percentage) / 100) : 1
    this.data.total = ((newTotal - sumDiscount) * percentDiscount).toFixed(2)
    this.data.total_with_vat = ((newTotalWithVat - sumDiscount * (1 + vatRate / 100)) * percentDiscount).toFixed(2)
    this.data.total_vat = ((newTotalVat - sumDiscount * (vatRate / 100)) * percentDiscount).toFixed(2)
  }

  async created(): Promise<void> {
    await this.getOfferItemOptions()
    if (this.$route.params.id) {
      this.noTemplate = true
      await this.getOfferData(Number(this.$route.params.id))
    }
    this.data.status = OfferStatusKeys.active
    this.data.date = moment().format('YYYY-MM-DD')
    this.data.due_date = moment().add(30, 'days').format('YYYY-MM-DD')
    this.data.manager_id = this.user.id
    if (this.$route.query.request_id) {
      this.data.request_id = Number(this.$route.query.request_id)
    }

    await getAll(this.$store)

    http
      .get('offers/get-next-number')
      .then((response: IResponse<IModelResponse>) => {
        this.loadingNumber = true
        this.data.number = response.data
        this.validNumber = true
      })
      .finally(() => {
        this.loadingNumber = false
      })
    this.calculateSums()
    this.loading = false
  }

  @Watch('data.discount_type')
  @Watch('data.discount')
  @Watch('data.discount_percentage')
  onDiscountChange(): void {
    this.calculateSums()
  }

  private debouncedCheckIfValid = _.debounce(() => this.checkIfValid(), 400)

  debouncedValidityCheck() {
    this.debouncedCheckIfValid()
  }

  addOfferHeading() {
    this.data.information.push({
      type: 'heading',
      position: this.data.information.length,
      title: ''
    })
  }

  addOfferService() {
    this.data.information.push({
      type: 'service',
      position: this.data.information.length,
      title: '',
      amount: '',
      price_per_unit: '',
      unit: '',
      vat: 0
    })
    this.itemsBlockKey++
  }

  checkIfValid(): any {
    if (!this.loadingNumber && this.data.number) {
      this.loadingNumber = true
      http
        .get(`offers/check-is-available/${this.data.number}`)
        .then((response: IResponse<IModelResponse>) => {
          if (!response.data) this.validNumber = false
          else this.validNumber = true
        })
        .finally(() => {
          this.loadingNumber = false
        })
    }
  }

  async changeTemplate() {
    await http
      .get(`offer-templates/${this.data.template_id}`)
      .then((response: IResponse<IModelResponse>) => response.data.data)
      .then((template: ITemplateResponse) => {
        this.data.notes = template.notes
        this.data.information = template.information
      })
  }

  openSaveTemplateModal(): void {
    this.$store.commit('setModalDialog', {
      component: OffersSaveAsTemplate,
      id: 'offerCreate',
      props: {
        title: __('views.offers.save-template.title'),
        size: 'md',
        submitLabel: __('form.save')
      },
      details: {
        data: {
          information: this.processInformation(this.data.information),
          notes: this.data.notes,
          discount_type: this.data.discount_type,
          discount: this.data.discount,
          discount_percentage: this.data.discount_percentage
        }
      }
    })
  }

  processInformation(information: any) {
    if (typeof information !== 'object') {
      return information
    }
    const output: { [k: string]: any } = {}
    Object.keys(information).forEach((key) => {
      output[key] = {
        ...information[key],
        position: key
      }
    })
    return output
  }

  recalculateItemRow(item: IOfferInformationItem, field:string) {
    if (field === 'vat' || field === 'price_per_unit' || field === 'amount') {
      item.total = Number(item.amount) * Number(item.price_per_unit)
      item.total_with_vat = item.total * (1 + Number(item.vat) / 100)
      item.total = item.total.toFixed(2)
      item.total_with_vat = item.total_with_vat.toFixed(2)
      item.total_vat = (Number(item.total_with_vat) - Number(item.total)).toFixed(2)
    }

    if (field === 'total') {
      item.price_per_unit = Number(item.total) / Number(item.amount)
      item.total_with_vat = Number(item.total) * (1 + Number(item.vat) / 100)
      item.price_per_unit = item.price_per_unit.toFixed(2)
      item.total_with_vat = item.total_with_vat.toFixed(2)
      item.total_vat = (Number(item.total_with_vat) - Number(item.total)).toFixed(2)
    }

    if (field === 'total_with_vat') {
      item.total = Number(item.total_with_vat) / (1 + Number(item.vat) / 100)
      item.price_per_unit = Number(item.total) / Number(item.amount)
      item.price_per_unit = item.price_per_unit.toFixed(2)
      item.total = item.total.toFixed(2)
      item.total_vat = (Number(item.total_with_vat) - Number(item.total)).toFixed(2)
    }
    this.calculateSums()
  }

  onItemFieldBlur(item: IOfferInformationItem, field: keyof IOfferInformationItem) {
    if (!(field in item)) {
      return
    }
    // @ts-ignore
    item[field] = Number(item[field]).toFixed(2)
    this.itemsBlockKey++
  }

  @Watch('data.information', { deep: true })
  onItemsChange(newItems: IOfferInformationItem[]): void {
    if (!this.previousItems.length) {
      this.previousItems = JSON.parse(JSON.stringify(newItems))
      return
    }

    newItems.forEach((newItem, index) => {
      const oldItem = this.previousItems[index]
      if (!oldItem) return

      const changedField = Object.keys(newItem).find(
        key => newItem[key as keyof IOfferInformationItem] !== oldItem[key as keyof IOfferInformationItem]
      )

      if (changedField) {
        this.recalculateItemRow(newItem, changedField)
      }
    })

    this.previousItems = JSON.parse(JSON.stringify(newItems))
  }
}
